import classNames from "classnames/bind";
import { getContentfulVideoUrl } from "../../../../helpers/getContentfulVideoUrl";
import { CosmosButton, CosmosIconReplay } from "@cosmos/web/react";
import styles from "./lifecycle-cta.module.css";
import { LifecycleCtaType } from "../lifecycle.types";

const cx = classNames.bind(styles);
const EVERY_SECOND_ITEM = 2;

export const LifecycleCta = ({
  cta,
  characterVideoUrl,
  index,
}: {
  cta: LifecycleCtaType;
  characterVideoUrl: string | null;
  index: number;
}) => (
  <div
    className={cx("container", {
      "container--silver-bg": index % EVERY_SECOND_ITEM !== 0,
    })}
  >
    <div className={cx("inner")}>
      <section className={cx("content")}>
        {characterVideoUrl && (
          <div
            className={cx(
              "video-wrapper",
              {
                "video-wrapper--gradient-grey": index % EVERY_SECOND_ITEM !== 0,
              },
              {
                "video-wrapper--gradient-white":
                  index % EVERY_SECOND_ITEM === 0,
              },
            )}
          >
            <video
              className={cx("video")}
              preload="auto"
              autoPlay={true}
              playsInline={true}
              muted={true}
              loop={true}
              src={
                characterVideoUrl
                  ? getContentfulVideoUrl(characterVideoUrl)
                  : undefined
              }
            />
          </div>
        )}
        <div
          className={cx("facts-wrapper", {
            "facts-wrapper--no-asset": !characterVideoUrl,
          })}
        >
          <h2
            className={cx("title", {
              "title--no-asset": !characterVideoUrl,
            })}
          >
            {cta.title}
          </h2>
          <div
            className={cx({
              buttons: cta.quizLinkUrl && cta.quizLinkText,
            })}
          >
            {cta.quizLinkUrl && cta.quizLinkText && (
              <CosmosButton
                href={cta.quizLinkUrl}
                kind="primary"
                size="large"
                className={cx("button")}
              >
                {cta.quizLinkText}
              </CosmosButton>
            )}
            <CosmosButton
              href="#lifecycle-stage--top"
              size="large"
              kind="tertiary"
              icon-placement="after"
              className={cx("button")}
            >
              <CosmosIconReplay slot="icon" />
              {cta.buttonText}
            </CosmosButton>
          </div>
        </div>
      </section>
    </div>
  </div>
);
