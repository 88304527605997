"use client";

import classnames from "classnames/bind";
import { RbVideo } from "../../../rb-video/rb-video";
import { useViewportDimensions } from "../../../../helpers/useViewportDimensions";
import { DietaryMarks } from "../../../dietary-marks/dietary-marks";
import styles from "./lifecycle-stage.module.css";
import { NewLifecycleStage } from "../lifecycle.types";

const cx = classnames.bind(styles);

const MOBILE_BREAKPOINT = 500;

export const LifecycleNewStage = ({
  title,
  kicker,
  text,
  videoPlayerId,
  videoText,
  hasTeaser,
}: Pick<
  NewLifecycleStage,
  "kicker" | "title" | "text" | "videoText" | "videoPlayerId"
> & { hasTeaser: boolean }) => {
  const { viewportWidth } = useViewportDimensions();

  return (
    <div
      id="container--top"
      className={cx("container", {
        "container--has-teaser": hasTeaser,
      })}
    >
      <div className={cx("inner")}>
        <div className={cx("content")}>
          <h1 className={cx("kicker")}>{kicker}</h1>
          <h2 className={cx("title")}>{title}</h2>
          <p className={cx("text")}>{text}</p>
          <section className={cx("video-stage")}>
            <div className={cx("video-container")}>
              {videoPlayerId && (
                <RbVideo
                  id={videoPlayerId}
                  isAutoPlaying={viewportWidth > MOBILE_BREAKPOINT}
                  isLooping={true}
                />
              )}
            </div>
            <p className={cx("text", "video-text")}>{videoText}</p>
          </section>
          <DietaryMarks
            kind="high-sugar"
            className={cx("dietary-mark-high-sugar")}
          />
        </div>
      </div>
    </div>
  );
};
