"use client";

import { LifecycleNewStage } from "./lifecycle-stage/lifecycle-stage";
import { LifecyclePhase } from "./lifecycle-phase/lifecycle-phase";
import { LifecycleCta } from "./lifecycle-cta/lifecycle-cta";
import { OrganicsTeaserCard } from "../organics-teaser-card/organics-teaser-card";
import { ProductHighlightPanel } from "../product-highlight-panel/product-highlight-panel";
import { ProductTeaserCard } from "../product-teaser-card/product-teaser-card";
import { SustainabilityRail } from "../sustainability-rail/sustainability-rail";
import classNames from "classnames/bind";
import {
  isLifecyclePhase,
  isBlocksProductTeaserCard,
  isBlocksProductHighlightPanel,
} from "../../../helpers/typeguards";
import { notNull } from "../../../helpers/notNull";
import { useState } from "react";
import styles from "./lifecycle.module.css";
import {
  NewLifecycleProps,
  LifecycleNewStageTeaserCard,
} from "./lifecycle.types";

const cx = classNames.bind(styles);

export const NewLifecycle = ({
  phasesCollection,
  stage,
}: NewLifecycleProps) => {
  const lifecyclePhases =
    phasesCollection?.filter(notNull).filter(isLifecyclePhase) || [];

  const [activeLifecyclePhase, setActiveLifecyclePhase] = useState<
    string | undefined
  >();

  return (
    <div>
      {stage && (
        <LifecycleNewStage
          kicker={stage.kicker}
          title={stage.title}
          text={stage.text || stage.textLong}
          videoText={stage.videoText}
          videoPlayerId={stage.videoPlayerId}
          hasTeaser={!!(stage.rail || stage.teaserCard)}
        />
      )}
      {stage?.rail && (
        <SustainabilityRail
          title={stage?.rail.title ?? null}
          text={stage.rail.text ?? null}
          cardsCollection={
            stage.rail.cardsCollection?.map((item) => ({
              title: item?.title ?? null,
              description: item?.description ?? null,
              link: item?.link ?? null,
              linkText: item?.linkText ?? null,
              media: {
                contentType: item?.media?.contentType ?? null,
                url: item?.media?.url ?? null,
                description: item?.media?.description ?? null,
              },
            })) ?? null
          }
        />
      )}
      {stage?.teaserCard && <TeaserCard card={stage.teaserCard} />}
      <nav className={cx("navigation")}>
        <div className={cx("navigation-wrapper")}>
          <div className={cx("navigation-inner-wrapper")}>
            {lifecyclePhases.map((phase, index) => (
              <a
                key={index}
                className={cx("navigation-link", {
                  active: activeLifecyclePhase === phase.id,
                })}
                href={`#${phase.title?.toLowerCase()}`}
                data-ref={phase.title?.toLowerCase()}
              >
                {phase.title}
              </a>
            ))}
          </div>
        </div>
      </nav>
      {phasesCollection?.filter(notNull).map((item, index) => {
        switch (item.__typename) {
          case "LifecyclePhase":
            return (
              <LifecyclePhase
                key={index}
                phase={item}
                characterVideoUrl={item.videoUrl ?? null}
                index={index}
                onEnterViewport={setActiveLifecyclePhase}
              />
            );
          case "LifecycleCta":
            return (
              <LifecycleCta
                key={index}
                cta={item}
                characterVideoUrl={item.videoUrl}
                index={index}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

function TeaserCard({ card }: { card: LifecycleNewStageTeaserCard }) {
  if (isBlocksProductHighlightPanel(card)) {
    return (
      <ProductHighlightPanel
        kicker={card.kicker}
        headline={card.headline}
        copy={card.copy}
        disclaimer={card.disclaimer}
        ctaText={card.ctaText}
        ctaLink={card.ctaLink}
        legalText={card.legalText}
        productImage={card.productImage}
      />
    );
  } else if (isBlocksProductTeaserCard(card)) {
    if (card.productType === "organics") {
      return (
        <OrganicsTeaserCard
          name={card.name}
          description={card.description}
          linkText={card.linkText}
          linkUrl={card.linkUrl}
          backgroundColor={card.backgroundColor}
          backgroundImage={{
            description: null, // TODO I don't see this in the type
            url: card.backgroundImageUrl,
          }}
          logoHeading={{
            description: null, // TODO
            url: card.logoHeadingUrl,
          }}
          image={{
            description: null, // TODO
            url: card.imageUrl,
          }}
        />
      );
    }
    return (
      <ProductTeaserCard
        name={card.name}
        title={card.title}
        description={card.description}
        disclaimer={card.disclaimer}
        centerDisclaimer={card.centerDisclaimer}
        linkText={card.linkText}
        linkUrl={card.linkUrl}
        backgroundColor={card.backgroundColor}
        smallImage={card.smallImage}
        productType={card.productType}
        backgroundImage={{ url: card.backgroundImageUrl, description: null }}
        mobileBackgroundImage={{
          url: card.mobileBackgroundImageUrl,
          description: null,
        }}
        logoHeading={{ url: card.logoHeadingUrl, description: null }}
        image={{ url: card.imageUrl, description: null }}
      />
    );
  }

  return null;
}
