"use client";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { CosmosButton } from "@cosmos/web/react";
import { getContentfulVideoUrl } from "../../../helpers/getContentfulVideoUrl";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import classnames from "classnames/bind";
import { forwardRef, useLayoutEffect, useRef } from "react";
import { waitForScrollConfig } from "../interactive-hero/utils/interactive-hero-utils";
import type { CosmosButton as CosmosButtonType } from "@cosmos/web/lib/custom-elements/cosmos-button";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import styles from "./product-highlight-panel.module.css";
import { BlocksProductHighlightPanel } from "../lifecycle/lifecycle.types";

const cx = classnames.bind(styles);

export interface ProductHighlightPanelProps {
  kicker: string | null;
  headline: string | null;
  copy: string | null;
  disclaimer: string | null;
  ctaText: string | null;
  ctaLink: string | null;
  legalText: string | null;
  productImage: {
    description: string | null;
    contentType: string | null;
    url: string | null;
  } | null;
  animated?: boolean;
}

export const ProductHighlightPanel = ({
  kicker,
  headline,
  copy,
  disclaimer,
  ctaText,
  ctaLink,
  legalText,
  productImage,
  animated,
}: ProductHighlightPanelProps) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const kickerRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const copyRef = useRef<HTMLParagraphElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const buttonRef = useRef<CosmosButtonType | null>(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (!animated) {
      return;
    }
    const ctx = gsap.context(() => {
      const rootElement = rootRef.current;
      const kickerElement = kickerRef.current;
      const titleElement = titleRef.current;
      const copyElement = copyRef.current;
      const imageElement = imageRef.current;
      const buttonElement = buttonRef.current;

      const timeline = gsap.timeline();
      timeline.from(
        [
          rootElement,
          kickerElement,
          titleElement,
          copyElement,
          buttonElement,
          imageElement,
        ],
        { duration: 0.4, opacity: 0, stagger: 0.2 },
        0,
      );
      timeline.pause();
      const scrollTrigger = new ScrollTrigger({
        trigger: rootElement,
        start: "top 70%",
        animation: timeline,
        once: true,
      });
      scrollTrigger.disable();
      waitForScrollConfig().then(() => {
        scrollTrigger.enable();
      });
    });

    return () => ctx.revert();
  }, [animated]);

  return (
    <div ref={rootRef} className={cx("container")}>
      <div className={cx("inner")}>
        <div className={cx("main")}>
          <div className={cx("copy-container")}>
            {/* TODO: This is a unique kicker style, should it be converted */}
            {kicker && (
              <div
                ref={kickerRef}
                className={cx("kicker")}
                dangerouslySetInnerHTML={{
                  __html: (kicker && markDownHelper(kicker)) ?? undefined,
                }}
              />
            )}
            {headline && (
              <h2 ref={titleRef} className={cx("headline")}>
                {headline}
              </h2>
            )}
            {copy && (
              <p ref={copyRef} className={cx("copy")}>
                {copy}
              </p>
            )}
            {disclaimer && (
              <span className={cx("disclaimer")}>{disclaimer}</span>
            )}
            {ctaText && ctaLink && (
              <div className={cx("button-wrapper")}>
                <CosmosButton
                  ref={buttonRef}
                  href={ctaLink}
                  kind="primary"
                  size="large"
                  className={cx("button")}
                >
                  {ctaText}
                </CosmosButton>
              </div>
            )}
            {legalText && <p className={cx("legal-text")}>{legalText}</p>}
          </div>
          {productImage && <ProductAsset ref={imageRef} asset={productImage} />}
        </div>
      </div>
    </div>
  );
};

const ProductAsset = forwardRef<
  HTMLImageElement,
  {
    asset: NonNullable<BlocksProductHighlightPanel["productImage"]>;
  }
>(({ asset }, ref) => {
  const imageLoading = useImageLoading();
  if (asset.contentType === "video/mp4") {
    return (
      <div className={cx("video-container")}>
        <video
          className={cx("video")}
          preload="auto"
          autoPlay={true}
          playsInline={true}
          loop={true}
          muted={true}
          src={asset.url ? getContentfulVideoUrl(asset.url) : undefined}
        />
      </div>
    );
  }

  return (
    <div className={cx("image-container")}>
      {asset.url && (
        <img
          ref={ref}
          loading={imageLoading}
          srcSet={responsiveImageHelper.createContentfulSrcSet(asset.url)}
          sizes="(min-width: 1000px) min(50vw, 600px), min(100vw, 600px)"
          className={cx("image")}
          alt={asset.description ?? ""}
        />
      )}
    </div>
  );
});

function markDownHelper(string: string): string {
  const boldRegex = /_{1,2}(.*?)_{1,2}/g; // __something__
  const italicRegex = /\*{1,2}(.*?)\*{1,2}/g; // **something**

  return string
    .replace(boldRegex, "<strong>$1</strong>")
    .replace(italicRegex, "<em>$1</em>");
}
