"use client";

import classNames from "classnames/bind";
import { getContentfulImageUrl } from "../../../helpers/images/getContentfulImageUrl";
import { CosmosButton } from "@cosmos/web/react";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import { Kicker } from "../../kicker/kicker";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import { DietaryMarks } from "../../dietary-marks/dietary-marks";
import styles from "./product-teaser-card.module.css";

const cx = classNames.bind(styles);

export const ProductTeaserCard = ({
  name,
  title,
  description,
  disclaimer,
  centerDisclaimer,
  linkText,
  linkUrl,
  backgroundColor,
  smallImage,
  productType,
  backgroundImage,
  mobileBackgroundImage,
  logoHeading,
  image,
}: {
  name: string | null;
  title: string | null;
  description: string | null;
  disclaimer: string | null;
  centerDisclaimer: boolean | null;
  linkText: string | null;
  linkUrl: string | null;
  backgroundColor: string | null;
  smallImage: boolean | null;
  productType: string | null;
  backgroundImage: { url: string | null; description: string | null } | null;
  mobileBackgroundImage: {
    url: string | null;
    description: string | null;
  } | null;
  logoHeading: { url: string | null; description: string | null } | null;
  image: { url: string | null; description: string | null } | null;
}) => {
  const backgroundColorStyle = backgroundColor
    ? { backgroundColor: `${backgroundColor}` }
    : null;
  let backgroundImageStyle = null;

  if (backgroundImage) {
    const url =
      backgroundImage.url &&
      getContentfulImageUrl(backgroundImage.url, { width: 1000 });

    backgroundImageStyle =
      productType === "winter edition"
        ? {
            backgroundImage: `url(${url})`,
          }
        : {
            background: `url(${url}) no-repeat center center`,
            backgroundSize: "cover",
          };
  }

  const imageLoading = useImageLoading();

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <a
          className={cx("card", {
            "card--has-gradient": backgroundColor,
            "card--has-background": backgroundImage,
            "card--winter-edition": productType === "winter edition",
          })}
          href={linkUrl ?? undefined}
          style={backgroundImageStyle || backgroundColorStyle || undefined}
        >
          {mobileBackgroundImage?.url && (
            <img
              loading={imageLoading}
              srcSet={responsiveImageHelper.createContentfulSrcSet(
                mobileBackgroundImage.url,
              )}
              sizes="min(1222px, 100vw)"
              className={cx("mobile-background-image")}
              alt={mobileBackgroundImage.description ?? ""}
            />
          )}
          {backgroundImage?.url && (
            <img
              loading={imageLoading}
              srcSet={responsiveImageHelper.createContentfulSrcSet(
                backgroundImage.url,
              )}
              sizes="min(1222px, 100vw)"
              className={cx("background-image")}
              alt={backgroundImage.description ?? ""}
            />
          )}
          <div className={cx("text-content")}>
            <div>
              {logoHeading?.url && (
                <img
                  loading={imageLoading}
                  srcSet={responsiveImageHelper.createContentfulSrcSet(
                    logoHeading.url,
                  )}
                  sizes="(min-width: 1000px) 300px, (min-width: 650px) 200px, (min-width: 350px) 130px, 100px"
                  className={cx("logo")}
                  alt={logoHeading.description ?? ""}
                />
              )}
            </div>
            <div>
              {(name || title) && (
                <div className={cx("heading-group")}>
                  {name && (
                    <span className={cx("kicker-wrapper")}>
                      <Kicker kind="bare" text={name} />
                    </span>
                  )}
                  {title && !logoHeading && (
                    <h3 className={cx("title")}>{title}</h3>
                  )}
                </div>
              )}
              <p
                className={cx("text", {
                  "text--has-disclaimer": disclaimer,
                })}
              >
                {description}
              </p>
              {disclaimer && (
                <span
                  className={cx("disclaimer", {
                    "disclaimer--centered": centerDisclaimer,
                  })}
                >
                  {disclaimer}
                </span>
              )}
              {linkUrl && linkText && (
                <div className={cx("button-wrapper")}>
                  <CosmosButton
                    decorative={true}
                    size="large"
                    appearance="light"
                    kind={
                      backgroundColor || backgroundImage
                        ? "tertiary"
                        : "primary"
                    }
                    className={cx("button")}
                  >
                    {linkText}
                  </CosmosButton>
                </div>
              )}
            </div>
          </div>
          {image?.url && (
            <div
              className={cx("image-wrapper", {
                "image-wrapper--small": smallImage,
              })}
            >
              <img
                loading={imageLoading}
                srcSet={responsiveImageHelper.createContentfulSrcSet(image.url)}
                sizes="(min-width: 1000px) min(612px, 50vw), 300px"
                className={cx("image")}
                alt={image.description ?? ""}
              />
              <DietaryMarks
                kind="vegetarian"
                className={cx("dietary-mark-vegetarian")}
              />
            </div>
          )}
          {/* High Sugar mark currently not implimented */}
          {/* <DietaryMarks
            kind="high-sugar"
            className={cx("dietary-mark-high-sugar")}
            sugarFree={}
          /> */}
        </a>
      </div>
    </div>
  );
};
