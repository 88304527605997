"use client";

import React, { useCallback, useEffect, useState } from "react";
import { EmblaOptionsType, EmblaCarouselType } from "embla-carousel";
import { useKickerContext } from "../../../helpers/KickerContext";
import classnames from "classnames/bind";
import { getContentfulImageUrl } from "../../../helpers/images/getContentfulImageUrl";
import { getContentfulVideoUrl } from "../../../helpers/getContentfulVideoUrl";
import { notNull } from "../../../helpers/notNull";
import { SliderButton } from "../../slider-button/slider-button";
import useEmblaCarousel from "embla-carousel-react";
import { Kicker } from "../../kicker/kicker";
import style from "./sustainability-rail.module.css";
import { CosmosButton, CosmosIconExternalLink } from "@cosmos/web/react";
import { useTextDirectionContext } from "../../../helpers/TextDirectionContext";
import { getImageSource } from "../../../helpers/getImageSource";
import { getStoryblokImageUrl } from "../../../helpers/images/getStoryblokImageUrl";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import { useTranslationsContext } from "../../../helpers/TranslationsContext";
import template from "../../../helpers/template";

const cx = classnames.bind(style);

type SustainabilityCardType = {
  title: string | null;
  description: string | null;
  link: string | null;
  linkText: string | null;
  media: {
    contentType: string | null;
    url: string | null;
    description: string | null;
  };
};

interface SustainabilityRailProps {
  title: string | null;
  text: string | null;
  cardsCollection: SustainabilityCardType[] | null;
}

export const SustainabilityRail = ({
  title,
  text,
  cardsCollection,
}: SustainabilityRailProps) => {
  const textDirection = useTextDirectionContext();
  const translations = useTranslationsContext();

  // Embla Options
  const EMBLA_OPTIONS: EmblaOptionsType = {
    align: "start",
    containScroll: "trimSnaps",
    direction: textDirection,
    loop: false,
    watchDrag: true,
  };

  // Embla Carousel
  const [emblaRef, emblaApi] = useEmblaCarousel(EMBLA_OPTIONS);

  // Embla Prev/Next buttons
  const [prevBtnDisabled, setPrevBtnDisabled] = useState<boolean>(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState<boolean>(true);

  // Embla Prev button
  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  // Embla Next button
  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  // Embla onSelect
  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  // Embla useEffect
  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  const kickerKind = useKickerContext();

  return (
    <section className={cx("container")}>
      {title && (
        <span className={cx("title-kicker-wrapper")}>
          <Kicker className={cx("kicker")} kind={kickerKind} text={title} />
        </span>
      )}
      {text && <p className={cx("text")}>{text}</p>}
      <div className={cx("rail")}>
        <SliderButton
          kind="previous"
          className={cx("slider-button", "slider-button--prev")}
          onClick={onPrevButtonClick}
          disabled={prevBtnDisabled}
          aria-controls="sustainability-rail"
          aria-label={template(
            translations["carousel.controls.previous"],
            "Previous",
          )}
        />
        <SliderButton
          kind="next"
          className={cx("slider-button", "slider-button--next")}
          onClick={onNextButtonClick}
          disabled={nextBtnDisabled}
          aria-controls="sustainability-rail"
          aria-label={template(
            translations["carousel.controls.next"],
            "Previous",
          )}
        />
        <div
          className={cx("embla-viewport")}
          ref={emblaRef}
          dir={textDirection}
          role="group"
          id="sustainability-rail"
        >
          <div className={cx("embla-container")}>
            {cardsCollection &&
              cardsCollection.filter(notNull).map((item, index) => (
                <div key={index} className={cx("embla-slide")}>
                  <SustainabilityCard key={index} item={item} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

function SustainabilityCard({ item }: { item: SustainabilityCardType }) {
  const mediaUrl = item.media.url;
  const mediaSource = mediaUrl ? getImageSource(mediaUrl) : null;
  const src = (() => {
    if (!mediaUrl || !mediaSource) {
      return undefined;
    }

    switch (true) {
      case mediaSource === "contentful":
        return item.media.contentType === "video/mp4"
          ? getContentfulVideoUrl(mediaUrl)
          : getContentfulImageUrl(mediaUrl, {});
      case mediaSource === "storyblok":
        return getStoryblokImageUrl(mediaUrl);
      default:
        return mediaUrl;
    }
  })();

  const srcSet =
    item.media.contentType === "video/mp4" || !mediaUrl
      ? undefined
      : mediaSource === "contentful"
        ? responsiveImageHelper.createContentfulSrcSet(mediaUrl)
        : mediaSource === "storyblok"
          ? responsiveImageHelper.createStoryblokSrcSet(mediaUrl)
          : undefined;

  return (
    <div className={cx("card")} key={item.title ?? undefined}>
      {item.media && (
        <div className={cx("image-wrapper")}>
          {item.media.contentType === "video/mp4" ? (
            <video
              className={cx("image")}
              preload="auto"
              autoPlay={true}
              playsInline={true}
              muted={true}
              loop={true}
              src={src}
            />
          ) : (
            <img
              className={cx("image")}
              src={src}
              alt={item.media.description ?? undefined}
              srcSet={srcSet}
            />
          )}
        </div>
      )}
      <div className={cx("card-content")}>
        {item.title && <h4 className={cx("card-title")}>{item.title}</h4>}
        {item.description && (
          <p className={cx("card-description")}>{item.description}</p>
        )}
        {item.link && (
          <CosmosButton
            className={cx("link")}
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            kind="link"
            iconPlacement="before"
          >
            <CosmosIconExternalLink slot="icon" />
            {item.linkText ? item.linkText : item.link}
          </CosmosButton>
        )}
      </div>
    </div>
  );
}
